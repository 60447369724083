<template>
  <div class="position-relative table-responsive table-base">
    <!-- Modal pour ajouter une nouvelle taxe -->
    <b-modal
      id="e-add-taxe"
      cancel-variant="outline-secondary"
      ok-title="Créer"
      cancel-title="Annuler"
      centered
      title="Ajouter une nouvelle taxe"
    >
      <b-form>
        <!-- nom -->
        <b-form-group label-for="register-nom">
          <label for="">Nom <span class="p-0 text-danger h6">*</span></label>
          <b-form-input
            id="register-nom"
            v-model="inputTaxe.name"
            name="register-nom"
            placeholder="TVA"
          />

          <small v-if="inputError.path === 'name'">
            {{ inputError.message }}
          </small>
        </b-form-group>
        <!-- valeur -->
        <b-form-group label-for="register-nom">
          <label for=""
            >Valeur de la taxe (%)
            <span class="p-0 text-danger h6">*</span></label
          >
          <b-form-input
            type="number"
            id="register-nom"
            v-model="inputTaxe.value"
            name="register-nom"
            placeholder="18"
          />
          <small v-if="inputError.path === 'value'">
            {{ inputError.message }}
          </small>
        </b-form-group>
      </b-form>
      <template #modal-footer="{}">
        <b-button
          :disabled="state.loading === true ? true : false"
          variant="primary"
          @click="addTaxe"
        >
          <span v-if="state.loading === false">Ajouer</span>
          <b-spinner v-if="state.loading === true" label="Spinning"></b-spinner>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ref, reactive, watchEffect } from '@vue/composition-api';
import URL from '@/views/pages/request';
import axios from 'axios';
import moment from 'moment';
import { toast_sucess } from '@/utils/qToast';
export default {
  props: {
    actionMode: String,
    data: Object,
    taxes: Array,
    success: Boolean
  },
  setup(props, { root }) {
    const state = reactive({
      loading: false,
    });
    const inputError = reactive({
      path: '',
      message: '',
    });
    const inputTaxe = reactive({
      name: '',
      value: '',
    });
    const digit = /^\d+$/;

    const actionMode__title = ref(
      props.actionMode === 'e-add-taxe'
        ? 'Ajouter un nouvelle taxe'
        : 'Modifier la taxe'
    );

    watchEffect(() => {
        console.log(props.actionMode)
    })

    // Functions Space
    const validate = (field) => {
      const isError = false;
      if (field.name === '') {
        inputError.path = 'name';
        inputError.message = 'Le libelle est requis !';
        return {
          error: true,
        };
      } else if (field.name.length > 15) {
        inputError.path = 'name';
        inputError.message = 'Le libelle requis 15 charatere maximuin';
      } else if (field.value === '') {
        inputError.path = 'value';
        inputError.message = 'La valeur est requis !';
        return {
          error: true,
        };
      } else if (!digit.test(field.value)) {
        inputError.path = 'value';
        inputError.message = 'Entrez une value valide';
        return {
          error: true,
        };
      } else if (parseInt(field.value) > 100) {
        inputError.path = 'value';
        inputError.message = 'La valeur ne dois pas exceder les 100%';
        return {
          error: true,
        };
      } else {
        return {
          error: false,
        };
      }
    };

    //Add Taxe
    const addTaxe = async () => {
        state.loading = true
      try {
        const { error } = validate(inputTaxe);
        console.log(error);
        if (!error) {
            const newData = {
                libelle: inputTaxe.name,
                valeur: inputTaxe.value,
            }
          const { data } = await axios.post(URL.TAXE_CREATE, newData);

          if (data) {
            console.log(data);
            data.taxe[0].created_at = moment(data.taxe[0].created_at).format('DD-MM-YYYY')
            props.taxes.unshift(data.taxe[0])
            root.$bvModal.hide('e-add-taxe')
            state.loading = false
            toast_sucess(root, 'success', 'top-right', 'Taxe créer avec succès !')
          }
        }
      } catch (error) {
          state.loading = false
        console.log(error);
      }
    };

    //
    const updateTaxe = () => {};

    //
    return {
      state,
      inputError,
      inputTaxe,
      actionMode__title,

      //
      addTaxe,
      updateTaxe,
    };
  },
};
</script>
